import React from "react";

export default ({ color }) => (
	<svg width="72" height="50" viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.45517 7.13793H26.943V22.3574H6.45517V7.13793Z" fill="#F76E9C" />
		<path d="M44.069 30.0288H64.5568V45.2483H44.069V30.0288Z" fill="#FFB000" />
		<path d="M31.68 7.32H52.1678V22.5395H31.68V7.32Z" fill="#73A77B" />
		<path d="M31.531 27H43.8237V42.2195H31.531V27Z" fill="#F76E9C" />
		<path d="M14.6483 27H26.941V42.2195H14.6483V27Z" fill="#73A77B" />
		<path d="M15.75 13.5C15.3044 13.4992 14.8686 13.6307 14.4979 13.8779C14.1271 14.1251 13.8381 14.4768 13.6675 14.8884C13.4968 15.3 13.4523 15.7531 13.5394 16.1901C13.6266 16.627 13.8415 17.0283 14.157 17.343C16.9455 18.6802 19.8532 19.7529 22.842 20.547C20.493 17.7345 18.117 14.9355 17.3385 14.1615C16.9176 13.7392 16.3462 13.5013 15.75 13.5Z" fill="white" />
		<path d="M19.593 37.593C19.1686 38.0029 18.6003 38.2296 18.0103 38.2245C17.4204 38.2194 16.8561 37.9828 16.4389 37.5656C16.0217 37.1484 15.7851 36.5841 15.78 35.9942C15.7749 35.4042 16.0016 34.8359 16.4115 34.4115L23.8185 27H0V45C0 46.1935 0.474106 47.3381 1.31802 48.182C2.16193 49.0259 3.30653 49.5 4.5 49.5H27V30.1815L19.593 37.593Z" fill={color} />
		<path d="M10.9755 20.5245C9.70922 19.2582 8.99784 17.5408 8.99784 15.75C8.99784 13.9592 9.70922 12.2418 10.9755 10.9755C12.2418 9.70922 13.9592 8.99784 15.75 8.99784C17.5408 8.99784 19.2582 9.70922 20.5245 10.9755C21.537 11.988 24.4755 15.4755 27 18.4995V0H4.5C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5V22.5H14.7195C13.3733 22.0474 12.109 21.3803 10.9755 20.5245Z" fill={color} />
		<path d="M67.5 0H31.5V18.504C34.0245 15.4755 36.963 11.9925 37.9755 10.98C39.2486 9.75043 40.9536 9.07007 42.7235 9.08545C44.4933 9.10083 46.1863 9.81072 47.4378 11.0622C48.6893 12.3137 49.3992 14.0067 49.4146 15.7765C49.4299 17.5464 48.7496 19.2514 47.52 20.5245C46.3879 21.3799 45.1251 22.0469 43.7805 22.5H72V4.5C72 3.30653 71.5259 2.16193 70.682 1.31802C69.8381 0.474106 68.6935 0 67.5 0Z" fill={color} />
		<path d="M44.343 14.157C43.9211 13.7352 43.3489 13.4982 42.7523 13.4982C42.1556 13.4982 41.5834 13.7352 41.1615 14.157C40.3875 14.931 38.0115 17.73 35.6625 20.5425C38.6725 19.7434 41.5977 18.6538 44.397 17.289C44.7967 16.8623 45.0146 16.2967 45.0045 15.7121C44.9945 15.1274 44.7572 14.5697 44.343 14.157Z" fill="white" />
		<path d="M42.093 34.407C42.3079 34.6146 42.4793 34.8628 42.5972 35.1373C42.7151 35.4118 42.7772 35.7071 42.7798 36.0058C42.7824 36.3046 42.7255 36.6009 42.6124 36.8774C42.4992 37.1539 42.3321 37.4051 42.1209 37.6164C41.9096 37.8276 41.6584 37.9947 41.3819 38.1078C41.1054 38.221 40.8091 38.2779 40.5103 38.2753C40.2116 38.2727 39.9163 38.2106 39.6418 38.0927C39.3673 37.9748 39.1191 37.8034 38.9115 37.5885L31.5 30.1815V49.5H67.5C68.6935 49.5 69.8381 49.0259 70.682 48.182C71.5259 47.3381 72 46.1935 72 45V27H34.6815L42.093 34.407ZM47.25 36H60.75C61.3467 36 61.919 36.2371 62.341 36.659C62.7629 37.081 63 37.6533 63 38.25C63 38.8467 62.7629 39.419 62.341 39.841C61.919 40.2629 61.3467 40.5 60.75 40.5H47.25C46.6533 40.5 46.081 40.2629 45.659 39.841C45.2371 39.419 45 38.8467 45 38.25C45 37.6533 45.2371 37.081 45.659 36.659C46.081 36.2371 46.6533 36 47.25 36Z" fill={color} />
	</svg>


);
